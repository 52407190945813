<script>
export default {
    props: {
        functions: {
            type: Array,
            required: false,
        },
        parent_id_tabela: {
            type: String,
            required: false,
        },
        id_tabela: {
            type: String,
            required: true,
        },
        callFunction: {
            type: Function,
            required: true,
        },
        showUploadFileModal: {
            type: Function,
            required: false,
        },
        showAddItemsModal: {
            type: Function,
            required: false,
        },
        registro_id: {
            type: String,
            required: false
        }
    },
    setup(props) {
        const getFunctionById = (id) => props.functions.find((f) => f.id_funcionalidade === id)

        return {
            getFunctionById,
        }
    },
}
</script>
<template>
    <div class="mb-1 d-flex gap-2 flex-wrap">
        <!-- Conta Financeira -> Integrações -->
        <template
            v-if="parent_id_tabela == 'e8f03ced-d35e-bbdd-c318-4c0cabdccebe' && id_tabela == 'd7cf7354-1652-d30f-25c4-323db6a3ce32'">
            <!-- Atualizar Certificado Digital -->
            <button v-if="getFunctionById('9fda9342-5381-4ead-a81d-627899de92d1')?.funcionalidade_habilitada"
                type="button" class="btn btn-ghost-secondary btn-icon btn-sm fs-20"
                v-b-tooltip.hover.top="getFunctionById('9fda9342-5381-4ead-a81d-627899de92d1')?.denominacao"
                @click="callFunction(getFunctionById('9fda9342-5381-4ead-a81d-627899de92d1'))">
                <i
                    :class="[getFunctionById('9fda9342-5381-4ead-a81d-627899de92d1')?.icone ?? 'ri-quill-pen-fill', 'align-bottom']" />
            </button>
        </template>
        <!-- Conta Financeira -> sis_usuario -->
        <template
            v-if="parent_id_tabela == 'e8f03ced-d35e-bbdd-c318-4c0cabdccebe' && id_tabela == '7bf45d63-5490-fbe1-78b7-dc6c73ee41ac'">
            <!-- Replicar Configuração -->
            <button v-if="getFunctionById('93049f4d-ea1c-49b5-aac2-ccf973e45629')?.funcionalidade_habilitada"
                type="button" class="btn btn-ghost-secondary btn-icon btn-sm fs-20"
                v-b-tooltip.hover.top="getFunctionById('93049f4d-ea1c-49b5-aac2-ccf973e45629')?.denominacao"
                @click="callFunction(getFunctionById('93049f4d-ea1c-49b5-aac2-ccf973e45629'))">
                <i
                    :class="[getFunctionById('93049f4d-ea1c-49b5-aac2-ccf973e45629')?.icone ?? 'ri-quill-pen-fill', 'align-bottom']" />
            </button>
        </template>
        <!-- Categoria/Departamento -> sis_usuario -->
        <template
            v-if="parent_id_tabela == '4b0fe29c-58fa-4b15-844c-fe2762a18020' && id_tabela == 'dcfbcad8-b5d3-41ed-91a0-3a45502f6676'">
            <!-- 'Visualizar Documentos' -->
            <button v-if="getFunctionById('93049f4d-ea1c-49b5-aac2-ccf973e45629')?.funcionalidade_habilitada"
                type="button" class="btn btn-ghost-secondary btn-icon btn-sm fs-20"
                v-b-tooltip.hover.top="getFunctionById('93049f4d-ea1c-49b5-aac2-ccf973e45629')?.denominacao"
                @click="callFunction(getFunctionById('93049f4d-ea1c-49b5-aac2-ccf973e45629'))">
                <i
                    :class="[getFunctionById('93049f4d-ea1c-49b5-aac2-ccf973e45629')?.icone ?? 'ri-quill-pen-fill', 'align-bottom']" />
            </button>
        </template>
        <!-- contas a receber -> sis_documento -->
        <template
            v-if="parent_id_tabela == '94bcbd6c-6ce6-d44f-24e3-414ac558b1b9' && id_tabela == 'a70db5f2-2187-cacf-3545-f3b8a9000888'">
            <!-- 'Visualizar Documentos' -->
            <button v-if="getFunctionById('3e95322a-8f41-4c21-8bfa-9c9908cdbeac')?.funcionalidade_habilitada"
                type="button" class="btn btn-ghost-secondary btn-icon btn-sm fs-20"
                v-b-tooltip.hover.top="getFunctionById('3e95322a-8f41-4c21-8bfa-9c9908cdbeac')?.denominacao"
                @click="callFunction(getFunctionById('3e95322a-8f41-4c21-8bfa-9c9908cdbeac'))">
                <i
                    :class="[getFunctionById('3e95322a-8f41-4c21-8bfa-9c9908cdbeac')?.icone ?? 'ri-quill-pen-fill', 'align-bottom']" />
            </button>
            <!-- Download Documentos -->
            <button v-if="getFunctionById('b9d6d7c6-34e6-44b2-a846-147306fe1c23')?.funcionalidade_habilitada"
                type="button" class="btn btn-ghost-secondary btn-icon btn-sm fs-20"
                v-b-tooltip.hover.top="getFunctionById('b9d6d7c6-34e6-44b2-a846-147306fe1c23')?.denominacao"
                @click="callFunction(getFunctionById('b9d6d7c6-34e6-44b2-a846-147306fe1c23'))">
                <i
                    :class="[getFunctionById('b9d6d7c6-34e6-44b2-a846-147306fe1c23')?.icone ?? 'ri-quill-pen-fill', 'align-bottom']" />
            </button>
        </template>
        <!-- painel -> sis_consulta_painel -->
        <template
            v-else-if="parent_id_tabela == '02ae2a7f-0b43-4ffc-af34-22e3a152be27' && id_tabela == '3172d174-b55e-4993-b863-48d5e92f6663'">
            <!-- Obeter dados consulta sql -->
            <button v-if="getFunctionById('aa6e3c22-575c-411b-9217-b9265be3e713')?.funcionalidade_habilitada"
                type="button" class="btn btn-ghost-secondary btn-icon btn-sm fs-20"
                v-b-tooltip.hover.top="getFunctionById('aa6e3c22-575c-411b-9217-b9265be3e713')?.denominacao"
                @click="callFunction(getFunctionById('aa6e3c22-575c-411b-9217-b9265be3e713'))">
                <i
                    :class="[getFunctionById('aa6e3c22-575c-411b-9217-b9265be3e713')?.icone ?? 'ri-quill-pen-fill', 'align-bottom']" />
            </button>
            <!-- Configurar Consulta -->
            <button type="button" class="btn btn-ghost-secondary btn-icon btn-sm fs-20"
                v-b-tooltip.hover.top="'Configurar Consulta'" @click="callFunction(
                {
                    icone: 'mdi link-primary mdi-view-dashboard-edit',
                    denominacao: 'Configurar Consulta',
                    rota_backend: null,
                    id_funcionalidade: null,
                    permite_operacoes_lote: false,
                    funcionalidade_habilitada: true
                }
            )">
                <i class="align-bottom mdi link-primary mdi-view-dashboard-edit" />
            </button>
        </template>
        <!-- solicitação de compra -> item_cotacao || cotação de compra -> item_cotacao-->
        <template
            v-else-if="['b5cdb60a-866e-f633-133a-31d2c1f0080d', '3e27db5c-cfa8-4435-aac7-267cb6079421'].includes(parent_id_tabela)
                && ['805e7a21-45ed-eba2-014e-a89550cef358', 'e5ebf9f1-13dc-477e-8c05-60186dcce22f'].includes(id_tabela)">
            <!-- Incluir Itens -->
            <button
                v-if="showAddItemsModal && getFunctionById('dc928673-e5e6-481d-8e1b-eac550b57bdf')?.funcionalidade_habilitada"
                type="button" class="btn btn-ghost-secondary btn-icon btn-sm fs-20"
                v-b-tooltip.hover.top="getFunctionById('dc928673-e5e6-481d-8e1b-eac550b57bdf')?.denominacao"
                @click="callFunction(getFunctionById('dc928673-e5e6-481d-8e1b-eac550b57bdf'))">
                <i
                    :class="[getFunctionById('dc928673-e5e6-481d-8e1b-eac550b57bdf')?.icone ?? 'ri-quill-pen-fill', 'align-bottom']" />
            </button>
            <!-- Excluir Itens -->
            <button v-if="getFunctionById('d965c114-bc5b-4d9b-8994-4904afb6425b')?.funcionalidade_habilitada"
                type="button" class="btn btn-ghost-secondary btn-icon btn-sm fs-20"
                v-b-tooltip.hover.top="getFunctionById('d965c114-bc5b-4d9b-8994-4904afb6425b')?.denominacao"
                @click="callFunction(getFunctionById('d965c114-bc5b-4d9b-8994-4904afb6425b'))">
                <i
                    :class="[getFunctionById('d965c114-bc5b-4d9b-8994-4904afb6425b')?.icone ?? 'ri-quill-pen-fill', 'align-bottom']" />
            </button>
            <!-- Aprovar Item -->
            <button v-if="getFunctionById('c74f780a-8876-42f5-87ec-c28d98f2ae3f')?.funcionalidade_habilitada"
                type="button" class="btn btn-ghost-secondary btn-icon btn-sm fs-20"
                v-b-tooltip.hover.top="getFunctionById('c74f780a-8876-42f5-87ec-c28d98f2ae3f')?.denominacao"
                @click="callFunction(getFunctionById('c74f780a-8876-42f5-87ec-c28d98f2ae3f'))">
                <i
                    :class="[getFunctionById('c74f780a-8876-42f5-87ec-c28d98f2ae3f')?.icone ?? 'ri-quill-pen-fill', 'align-bottom']" />
            </button>
            <!-- Reprovar Item -->
            <button v-if="getFunctionById('40cec6b1-9fce-47d5-b0e2-eadd3be50c63')?.funcionalidade_habilitada"
                type="button" class="btn btn-ghost-secondary btn-icon btn-sm fs-20"
                v-b-tooltip.hover.top="getFunctionById('40cec6b1-9fce-47d5-b0e2-eadd3be50c63')?.denominacao"
                @click="callFunction(getFunctionById('40cec6b1-9fce-47d5-b0e2-eadd3be50c63'))">
                <i
                    :class="[getFunctionById('40cec6b1-9fce-47d5-b0e2-eadd3be50c63')?.icone ?? 'ri-quill-pen-fill', 'align-bottom']" />
            </button>
            <!-- Gerar Cotação Itens -->
            <button v-if="getFunctionById('f9ec7e6d-9d0a-44e8-bb19-9fe899cbc73d')?.funcionalidade_habilitada"
                type="button" class="btn btn-ghost-secondary btn-icon btn-sm fs-20"
                v-b-tooltip.hover.top="getFunctionById('f9ec7e6d-9d0a-44e8-bb19-9fe899cbc73d')?.denominacao"
                @click="callFunction(getFunctionById('f9ec7e6d-9d0a-44e8-bb19-9fe899cbc73d'))">
                <i
                    :class="[getFunctionById('f9ec7e6d-9d0a-44e8-bb19-9fe899cbc73d')?.icone ?? 'ri-quill-pen-fill', 'align-bottom']" />
            </button>
            <!-- Gerar Pedido Itens -->
            <button v-if="getFunctionById('ffa1e923-ddc3-40c2-90c2-ff235d6edb26')?.funcionalidade_habilitada"
                type="button" class="btn btn-ghost-secondary btn-icon btn-sm fs-20"
                v-b-tooltip.hover.top="getFunctionById('ffa1e923-ddc3-40c2-90c2-ff235d6edb26')?.denominacao"
                @click="callFunction(getFunctionById('ffa1e923-ddc3-40c2-90c2-ff235d6edb26'))">
                <i
                    :class="[getFunctionById('ffa1e923-ddc3-40c2-90c2-ff235d6edb26')?.icone ?? 'ri-quill-pen-fill', 'align-bottom']" />
            </button>
            <!-- Adicionar Fornecedor -->
            <button v-if="getFunctionById('3fd198d6-43e4-4a13-8def-873718d2138c')?.funcionalidade_habilitada"
                type="button" class="btn btn-ghost-secondary btn-icon btn-sm fs-20"
                v-b-tooltip.hover.top="getFunctionById('3fd198d6-43e4-4a13-8def-873718d2138c')?.denominacao"
                @click="callFunction(getFunctionById('3fd198d6-43e4-4a13-8def-873718d2138c'))">
                <i
                    :class="[getFunctionById('3fd198d6-43e4-4a13-8def-873718d2138c')?.icone ?? 'ri-quill-pen-fill', 'align-bottom']" />
            </button>
            <!-- Adicionar Negociação -->
            <button v-if="getFunctionById('e2e7e7a5-59a8-4b98-b7d7-483da5356dbd')?.funcionalidade_habilitada"
                type="button" class="btn btn-ghost-secondary btn-icon btn-sm fs-20"
                v-b-tooltip.hover.top="getFunctionById('e2e7e7a5-59a8-4b98-b7d7-483da5356dbd')?.denominacao"
                @click="callFunction(getFunctionById('e2e7e7a5-59a8-4b98-b7d7-483da5356dbd'))">
                <i
                    :class="[getFunctionById('e2e7e7a5-59a8-4b98-b7d7-483da5356dbd')?.icone ?? 'ri-quill-pen-fill', 'align-bottom']" />
            </button>
            <!-- Visualizar melhores preços -->
            <button v-if="getFunctionById('a2ec1dcd-7c12-4d06-86f0-f6776bb558a1')?.funcionalidade_habilitada"
                type="button" class="btn btn-ghost-secondary btn-icon btn-sm fs-20"
                v-b-tooltip.hover.top="getFunctionById('a2ec1dcd-7c12-4d06-86f0-f6776bb558a1')?.denominacao"
                @click="callFunction(getFunctionById('a2ec1dcd-7c12-4d06-86f0-f6776bb558a1'))">
                <i
                    :class="[getFunctionById('a2ec1dcd-7c12-4d06-86f0-f6776bb558a1')?.icone ?? 'ri-quill-pen-fill', 'align-bottom']" />
            </button>
        </template>
        <!-- solicitação de compra -> sis_documento || cotação de compra -> sis_documento || pedido de compra -> sis_documento -->
        <template v-else-if="['b5cdb60a-866e-f633-133a-31d2c1f0080d', '3e27db5c-cfa8-4435-aac7-267cb6079421','10bb42f1-6e78-46d2-9935-00243f32f23e'].includes(parent_id_tabela)
                && id_tabela == '3c4a4151-2aa0-46d4-886c-d06c24f1ee5b'">
            <!-- Incluir Documentos -->
            <button v-if="getFunctionById('dd34d8e4-d993-4e6b-8c99-a411a959550a')?.funcionalidade_habilitada"
                type="button" class="btn btn-ghost-secondary btn-icon btn-sm fs-20"
                v-b-tooltip.hover.top="getFunctionById('dd34d8e4-d993-4e6b-8c99-a411a959550a')?.denominacao"
                @click="showUploadFileModal">
                <i
                    :class="[getFunctionById('dd34d8e4-d993-4e6b-8c99-a411a959550a')?.icone ?? 'ri-quill-pen-fill', 'align-bottom']" />
            </button>
            <!-- Download Documentos -->
            <button v-if="getFunctionById('b9d6d7c6-34e6-44b2-a846-147306fe1c23')?.funcionalidade_habilitada"
                type="button" class="btn btn-ghost-secondary btn-icon btn-sm fs-20"
                v-b-tooltip.hover.top="getFunctionById('b9d6d7c6-34e6-44b2-a846-147306fe1c23')?.denominacao"
                @click="callFunction(getFunctionById('b9d6d7c6-34e6-44b2-a846-147306fe1c23'))">
                <i
                    :class="[getFunctionById('b9d6d7c6-34e6-44b2-a846-147306fe1c23')?.icone ?? 'ri-quill-pen-fill', 'align-bottom']" />
            </button>
            <!-- Visualizar Documentos -->
            <button v-if="getFunctionById('3e95322a-8f41-4c21-8bfa-9c9908cdbeac')?.funcionalidade_habilitada"
                type="button" class="btn btn-ghost-secondary btn-icon btn-sm fs-20"
                v-b-tooltip.hover.top="getFunctionById('3e95322a-8f41-4c21-8bfa-9c9908cdbeac')?.denominacao"
                @click="callFunction(getFunctionById('3e95322a-8f41-4c21-8bfa-9c9908cdbeac'))">
                <i
                    :class="[getFunctionById('3e95322a-8f41-4c21-8bfa-9c9908cdbeac')?.icone ?? 'ri-quill-pen-fill', 'align-bottom']" />
            </button>
            <!-- Excluir Documentos -->
            <button v-if="getFunctionById('07fdec1e-448a-4d9a-90ea-4fc6f3e16b6d')?.funcionalidade_habilitada"
                type="button" class="btn btn-ghost-secondary btn-icon btn-sm fs-20"
                v-b-tooltip.hover.top="getFunctionById('07fdec1e-448a-4d9a-90ea-4fc6f3e16b6d')?.denominacao"
                @click="callFunction(getFunctionById('07fdec1e-448a-4d9a-90ea-4fc6f3e16b6d'))">
                <i
                    :class="[getFunctionById('07fdec1e-448a-4d9a-90ea-4fc6f3e16b6d')?.icone ?? 'ri-quill-pen-fill', 'align-bottom']" />
            </button>
            <!-- gerar pdf do pedido de compra -->
            <button v-if="getFunctionById('d57aa1bd-5f20-47a1-85c5-4a66dce16ec3')?.funcionalidade_habilitada"
                type="button" class="btn btn-ghost-secondary btn-icon btn-sm fs-20"
                v-b-tooltip.hover.top="getFunctionById('d57aa1bd-5f20-47a1-85c5-4a66dce16ec3')?.denominacao"
                @click="callFunction(getFunctionById('d57aa1bd-5f20-47a1-85c5-4a66dce16ec3'))">
                <i
                    :class="[getFunctionById('d57aa1bd-5f20-47a1-85c5-4a66dce16ec3')?.icone ?? 'ri-quill-pen-fill', 'align-bottom']" />
            </button>
        </template>
        <!-- formulário -> sis_formulario_resposta -->
        <template
            v-else-if="parent_id_tabela == '8e82a5c0-c9a4-4d16-a6a3-9559bcc359b7' && id_tabela == '4c63deb6-b393-4f2f-b489-a376c628a0d1'">
            <!-- Visualizar Respostas -->
            <button v-if="getFunctionById('5b9e1fe9-5fea-4b0f-8b8f-6b0ef0db9456')?.funcionalidade_habilitada"
                type="button" class="btn btn-ghost-secondary btn-icon btn-sm fs-20"
                v-b-tooltip.hover.top="getFunctionById('5b9e1fe9-5fea-4b0f-8b8f-6b0ef0db9456')?.denominacao"
                @click="callFunction(getFunctionById('5b9e1fe9-5fea-4b0f-8b8f-6b0ef0db9456'))">
                <i
                    :class="[getFunctionById('5b9e1fe9-5fea-4b0f-8b8f-6b0ef0db9456')?.icone ?? 'ri-quill-pen-fill', 'align-bottom']" />
            </button>
        </template>
        <!-- contas a pagar -> sis_documento -->
        <template
            v-else-if="parent_id_tabela == '0c31e745-1943-4227-827d-ff9fb800bcee' && id_tabela == 'a70db5f2-2187-cacf-3545-f3b8a9000888'">
            <!-- 'Visualizar Documentos' -->
            <button v-if="getFunctionById('3e95322a-8f41-4c21-8bfa-9c9908cdbeac')?.funcionalidade_habilitada"
                type="button" class="btn btn-ghost-secondary btn-icon btn-sm fs-20"
                v-b-tooltip.hover.top="getFunctionById('3e95322a-8f41-4c21-8bfa-9c9908cdbeac')?.denominacao"
                @click="callFunction(getFunctionById('3e95322a-8f41-4c21-8bfa-9c9908cdbeac'))">
                <i
                    :class="[getFunctionById('3e95322a-8f41-4c21-8bfa-9c9908cdbeac')?.icone ?? 'ri-quill-pen-fill', 'align-bottom']" />
            </button>
            <!-- Download Documentos -->
            <button v-if="getFunctionById('b9d6d7c6-34e6-44b2-a846-147306fe1c23')?.funcionalidade_habilitada"
                type="button" class="btn btn-ghost-secondary btn-icon btn-sm fs-20"
                v-b-tooltip.hover.top="getFunctionById('b9d6d7c6-34e6-44b2-a846-147306fe1c23')?.denominacao"
                @click="callFunction(getFunctionById('b9d6d7c6-34e6-44b2-a846-147306fe1c23'))">
                <i
                    :class="[getFunctionById('b9d6d7c6-34e6-44b2-a846-147306fe1c23')?.icone ?? 'ri-quill-pen-fill', 'align-bottom']" />
            </button>
            <!-- Incluir Documentos -->
            <button v-if="getFunctionById('dd34d8e4-d993-4e6b-8c99-a411a959550a')?.funcionalidade_habilitada"
                type="button" class="btn btn-ghost-secondary btn-icon btn-sm fs-20"
                v-b-tooltip.hover.top="getFunctionById('dd34d8e4-d993-4e6b-8c99-a411a959550a')?.denominacao"
                @click="showUploadFileModal">
                <i
                    :class="[getFunctionById('dd34d8e4-d993-4e6b-8c99-a411a959550a')?.icone ?? 'ri-quill-pen-fill', 'align-bottom']" />
            </button>
            <!-- Excluir Documentos -->
            <button v-if="getFunctionById('07fdec1e-448a-4d9a-90ea-4fc6f3e16b6d')?.funcionalidade_habilitada"
                type="button" class="btn btn-ghost-secondary btn-icon btn-sm fs-20"
                v-b-tooltip.hover.top="getFunctionById('07fdec1e-448a-4d9a-90ea-4fc6f3e16b6d')?.denominacao"
                @click="callFunction(getFunctionById('07fdec1e-448a-4d9a-90ea-4fc6f3e16b6d'))">
                <i
                    :class="[getFunctionById('07fdec1e-448a-4d9a-90ea-4fc6f3e16b6d')?.icone ?? 'ri-quill-pen-fill', 'align-bottom']" />
            </button>
        </template>
        <!-- contas a pagar, solicitação de pagameno, contas a pagar 2.0 -> Formulário principal -->
        <template v-else-if="parent_id_tabela == undefined && ['0c31e745-1943-4227-827d-ff9fb800bcee', '9c7d3a9b-9420-45a7-891b-6c308c68e009', '256c354a-3990-43cb-a03e-f0e1bfe64366'].includes(id_tabela)">
            <!-- 'Configurar Boleto' -->
            <button id="f_button_6d22f883-df90-4d94-9077-4d0ce3ec516a" v-if="getFunctionById('6d22f883-df90-4d94-9077-4d0ce3ec516a')?.funcionalidade_habilitada"
                type="button" class="btn btn-ghost-secondary btn-icon btn-sm fs-20"
                v-b-tooltip.hover.top="'(Ctrl + Shift + 1) ' + getFunctionById('6d22f883-df90-4d94-9077-4d0ce3ec516a')?.denominacao"
                @click="callFunction(getFunctionById('6d22f883-df90-4d94-9077-4d0ce3ec516a'))">
                <i
                    :class="[getFunctionById('6d22f883-df90-4d94-9077-4d0ce3ec516a')?.icone ?? 'ri-quill-pen-fill', 'align-bottom']" />
            </button>
            <!-- 'Configurar PIX' -->
            <button id="f_button_84a4e6a3-967d-44ec-92bf-517002fb6c4a" v-if="getFunctionById('84a4e6a3-967d-44ec-92bf-517002fb6c4a')?.funcionalidade_habilitada"
                type="button" class="btn btn-ghost-secondary btn-icon btn-sm fs-20"
                v-b-tooltip.hover.top="'(Ctrl + Shift + 2) ' + getFunctionById('84a4e6a3-967d-44ec-92bf-517002fb6c4a')?.denominacao"
                @click="callFunction(getFunctionById('84a4e6a3-967d-44ec-92bf-517002fb6c4a'))">
                <i
                    :class="[getFunctionById('84a4e6a3-967d-44ec-92bf-517002fb6c4a')?.icone ?? 'ri-quill-pen-fill', 'align-bottom']" />
            </button>
            <!-- 'Configurar TED/DOC' -->
            <button id="f_button_75c360a4-80b8-481a-ab91-41e9d303c409" v-if="getFunctionById('75c360a4-80b8-481a-ab91-41e9d303c409')?.funcionalidade_habilitada"
                type="button" class="btn btn-ghost-secondary btn-icon btn-sm fs-20"
                v-b-tooltip.hover.top="'(Ctrl + Shift + 3) ' + getFunctionById('75c360a4-80b8-481a-ab91-41e9d303c409')?.denominacao"
                @click="callFunction(getFunctionById('75c360a4-80b8-481a-ab91-41e9d303c409'))">
                <i
                    :class="[getFunctionById('75c360a4-80b8-481a-ab91-41e9d303c409')?.icone ?? 'ri-quill-pen-fill', 'align-bottom']" />
            </button>
            <!-- 'Configurar Tributo/Concessionaria' -->
            <button id="f_button_1de8c5d7-fb67-41d8-b990-eb76ccc6c115" v-if="getFunctionById('1de8c5d7-fb67-41d8-b990-eb76ccc6c115')?.funcionalidade_habilitada"
                type="button" class="btn btn-ghost-secondary btn-icon btn-sm fs-20"
                v-b-tooltip.hover.top="'(Ctrl + Shift + 4) ' + getFunctionById('1de8c5d7-fb67-41d8-b990-eb76ccc6c115')?.denominacao"
                @click="callFunction(getFunctionById('1de8c5d7-fb67-41d8-b990-eb76ccc6c115'))">
                <i
                    :class="[getFunctionById('1de8c5d7-fb67-41d8-b990-eb76ccc6c115')?.icone ?? 'ri-quill-pen-fill', 'align-bottom']" />
            </button>
            <!-- 'Configurar QR Code' -->
            <button id="f_button_c8d1240f-680d-49c2-bf18-64d53ef47430" v-if="getFunctionById('c8d1240f-680d-49c2-bf18-64d53ef47430')?.funcionalidade_habilitada"
                type="button" class="btn btn-ghost-secondary btn-icon btn-sm fs-20"
                v-b-tooltip.hover.top="'(Ctrl + Shift + 5) ' + getFunctionById('c8d1240f-680d-49c2-bf18-64d53ef47430')?.denominacao"
                @click="callFunction(getFunctionById('c8d1240f-680d-49c2-bf18-64d53ef47430'))">
                <i
                    :class="[getFunctionById('c8d1240f-680d-49c2-bf18-64d53ef47430')?.icone ?? 'ri-quill-pen-fill', 'align-bottom']" />
            </button>
             <!-- Agendar pagamento -->
             <button id="f_button_f8768f85-670f-4d87-a657-3b62f3f2243f" v-if="getFunctionById('f8768f85-670f-4d87-a657-3b62f3f2243f')?.funcionalidade_habilitada"
                type="button" class="btn btn-ghost-secondary btn-icon btn-sm fs-20"
                v-b-tooltip.hover.top="'(Ctrl + Shift + 6) ' + getFunctionById('f8768f85-670f-4d87-a657-3b62f3f2243f')?.denominacao"
                @click="callFunction(getFunctionById('f8768f85-670f-4d87-a657-3b62f3f2243f'))">
                <i
                    :class="[getFunctionById('f8768f85-670f-4d87-a657-3b62f3f2243f')?.icone ?? 'ri-quill-pen-fill', 'align-bottom']" />
            </button>
            <!-- Estornar reprovação -->
            <button id="f_button_bdb1014d-5f0f-452b-9463-7590be2d2e80" v-if="getFunctionById('bdb1014d-5f0f-452b-9463-7590be2d2e80')?.funcionalidade_habilitada"
                type="button" class="btn btn-ghost-secondary btn-icon btn-sm fs-20"
                v-b-tooltip.hover.top="getFunctionById('bdb1014d-5f0f-452b-9463-7590be2d2e80')?.denominacao"
                @click="callFunction(getFunctionById('bdb1014d-5f0f-452b-9463-7590be2d2e80'))">
                <i
                    :class="[getFunctionById('bdb1014d-5f0f-452b-9463-7590be2d2e80')?.icone ?? 'ri-quill-pen-fill', 'align-bottom']" />
            </button>
            <!-- Desconfigurar título -->
            <button id="f_button_e8705089-6fd4-47d5-bbcb-58b090e8c8e7" v-if="getFunctionById('e8705089-6fd4-47d5-bbcb-58b090e8c8e7')?.funcionalidade_habilitada"
                type="button" class="btn btn-ghost-secondary btn-icon btn-sm fs-20"
                v-b-tooltip.hover.top="'(Ctrl + Shift + 7) ' + getFunctionById('e8705089-6fd4-47d5-bbcb-58b090e8c8e7')?.denominacao"
                @click="callFunction(getFunctionById('e8705089-6fd4-47d5-bbcb-58b090e8c8e7'))">
                <i
                    :class="[getFunctionById('e8705089-6fd4-47d5-bbcb-58b090e8c8e7')?.icone ?? 'ri-quill-pen-fill', 'align-bottom']" />
            </button>
             <!-- Liquidar Pagamento -->
             <button id="f_button_3573eb53-1c26-4b8c-af80-8e2b9d25ae10" v-if="id_tabela === '9c7d3a9b-9420-45a7-891b-6c308c68e009' && getFunctionById('3573eb53-1c26-4b8c-af80-8e2b9d25ae10')?.funcionalidade_habilitada"
             type="button" class="btn btn-ghost-secondary btn-icon btn-sm fs-20"
             v-b-tooltip.hover.top="getFunctionById('3573eb53-1c26-4b8c-af80-8e2b9d25ae10')?.denominacao"
             @click="callFunction(getFunctionById('3573eb53-1c26-4b8c-af80-8e2b9d25ae10'))">
             <i
                 :class="[getFunctionById('3573eb53-1c26-4b8c-af80-8e2b9d25ae10')?.icone ?? 'ri-quill-pen-fill', 'align-bottom']" />
         </button>
        </template>
        <!-- financeiro -> consultas -> dda -->
        <template
            v-else-if="parent_id_tabela == '45ec557a-5ba3-0205-bdbc-c1d61b30c764' && id_tabela == '2ae81b73-e0fb-496a-8ea9-633c24e82f35'">
            <!-- 'Visualizar Documentos' -->
            <button v-if="getFunctionById('3e95322a-8f41-4c21-8bfa-9c9908cdbeac')?.funcionalidade_habilitada"
                type="button" class="btn btn-ghost-secondary btn-icon btn-sm fs-20"
                v-b-tooltip.hover.top="getFunctionById('3e95322a-8f41-4c21-8bfa-9c9908cdbeac')?.denominacao"
                @click="callFunction(getFunctionById('3e95322a-8f41-4c21-8bfa-9c9908cdbeac'))">
                <i
                    :class="[getFunctionById('3e95322a-8f41-4c21-8bfa-9c9908cdbeac')?.icone ?? 'ri-quill-pen-fill', 'align-bottom']" />
            </button>
            <!-- Download Documentos -->
            <button v-if="getFunctionById('b9d6d7c6-34e6-44b2-a846-147306fe1c23')?.funcionalidade_habilitada"
                type="button" class="btn btn-ghost-secondary btn-icon btn-sm fs-20"
                v-b-tooltip.hover.top="getFunctionById('b9d6d7c6-34e6-44b2-a846-147306fe1c23')?.denominacao"
                @click="callFunction(getFunctionById('b9d6d7c6-34e6-44b2-a846-147306fe1c23'))">
                <i
                    :class="[getFunctionById('b9d6d7c6-34e6-44b2-a846-147306fe1c23')?.icone ?? 'ri-quill-pen-fill', 'align-bottom']" />
            </button>
            <!-- Incluir Documentos -->
            <button v-if="getFunctionById('dd34d8e4-d993-4e6b-8c99-a411a959550a')?.funcionalidade_habilitada"
                type="button" class="btn btn-ghost-secondary btn-icon btn-sm fs-20"
                v-b-tooltip.hover.top="getFunctionById('dd34d8e4-d993-4e6b-8c99-a411a959550a')?.denominacao"
                @click="showUploadFileModal">
                <i
                    :class="[getFunctionById('dd34d8e4-d993-4e6b-8c99-a411a959550a')?.icone ?? 'ri-quill-pen-fill', 'align-bottom']" />
            </button>
            <!-- Excluir Documentos -->
            <button v-if="getFunctionById('07fdec1e-448a-4d9a-90ea-4fc6f3e16b6d')?.funcionalidade_habilitada"
                type="button" class="btn btn-ghost-secondary btn-icon btn-sm fs-20"
                v-b-tooltip.hover.top="getFunctionById('07fdec1e-448a-4d9a-90ea-4fc6f3e16b6d')?.denominacao"
                @click="callFunction(getFunctionById('07fdec1e-448a-4d9a-90ea-4fc6f3e16b6d'))">
                <i
                    :class="[getFunctionById('07fdec1e-448a-4d9a-90ea-4fc6f3e16b6d')?.icone ?? 'ri-quill-pen-fill', 'align-bottom']" />
            </button>
        </template>
    </div>
</template>